import axios from "axios";
import { emitToastr } from "./Utils";
import { v4 as uuidv4 } from "uuid";

//const API_URL = "http://localhost:3006/admin";
const API_URL = "https://app.motorepassebrasil.com/admin";

class Api {
  login = async (email, password, remember) => {
    const endpoint = "/auth";

    if (!window.localStorage.getItem("did")) {
      window.localStorage.setItem("did", uuidv4());
    }

    let result = await this.authPost(endpoint, { email, password, remember, did: window.localStorage.getItem("did") });

    if (result && result.token && result.user) {
      window.localStorage.setItem("token", result.token);
      delete result.token;
      window.localStorage.setItem("user", JSON.stringify(result.user));
      result.login_ok = true;
    }

    return result;
  };

  logout = async () => {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    window.location.href = "/login";
  };

  getUser = () => {
    let user = window.localStorage.getItem("user");
    try {
      user = JSON.parse(user);
      return user;
    } catch {
      return {};
    }
  };

  getToken = () => {
    return window.localStorage.getItem("token") || "";
  };

  getRemoteUser = async (did) => {
    const user = await this.authPost("/get-user", did, {});

    if (user && user._id) {
      window.localStorage.setItem("user", JSON.stringify(user));
      return user;
    }
  };

  uploadImage = async (image) => {
    const resp = await this.authPost("/upload-image", { image }, { multipart: true });
    return resp;
  };

  recoveryPassword = async (email) => {
    const resp = await this.authPost("/send-password-recovery", { email: email }, {});
    return resp;
  };

  changePassword = async (email, password, recovery_token) => {
    const resp = await this.authPost("/change-password", { email, password, recovery_token }, {});
    return resp;
  };

  getStores = async () => {
    const resp = await this.authPost("/get-stores", {}, {});
    return resp;
  };

  createOrUpdateStore = async (store) => {
    const resp = await this.authPost("/create-or-update-store", { store }, {});
    return resp;
  };

  getCars = async (data) => {
    this.getRemoteUser({ did: window.localStorage.getItem("did") }); //PROTEÇÃO CONTRA COMPARTILHAMENTO DE CONTAS
    const resp = await this.authPost("/get-cars", data, {});
    return resp;
  };

  createOrUpdateCar = async (car) => {
    const resp = await this.authPost("/create-or-update-car", { car }, {});
    return resp;
  };

  getUsers = async (data) => {
    const resp = await this.authPost("/get-users", data, {});
    return resp;
  };

  createOrUpdateUser = async (user) => {
    const resp = await this.authPost("/create-or-update-user", { user }, {});
    return resp;
  };

  changeUserPassword = async (old_password, password) => {
    const resp = await this.authPost("/change-user-password", { old_password, password }, {});
    return resp;
  };

  sendNewPassword = async (email) => {
    const resp = await this.authPost("/send-new-password", { email }, {});
    return resp;
  };

  getBrandModels = async (fipe_brand_id) => {
    const resp = await this.authPost("/get-brand-models", { fipe_brand_id }, {});
    return resp;
  };

  getModelTypesByYear = async (fipe_model_id) => {
    const resp = await this.authPost("/get-model-types-by-year", { fipe_model_id }, {});
    return resp;
  };

  getDashboardData = async () => {
    const resp = await this.authPost("/get-dashboard-data", {}, {});
    return resp;
  };

  checkErrorsWithStatusCode200 = (data) => {
    if (data.error) return emitToastr("error", data.message || "Erro não identificado!", "Erro");
    return data;
  };

  get = (url) =>
    axios
      .get(`${API_URL}${url}`)
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch(() => {
        emitToastr("error", "Erro genérico de servidor!", "Erro");
      });

  authGet = (url, data) =>
    axios
      .get(`${API_URL}${url}`, { headers: { auth: this.getToken() }, params: data })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
          if (err.response.data && err.response.data.logoff === true) this.logout();
        } else {
          if (err.response && err.response.data && err.response.data.error && err.response.data.message) return emitToastr("error", err.response.data.message);
          console.log("Erro genérico de servidor");
        }
      });

  authPost = (url, data, options) => {
    const headers = { auth: this.getToken() };
    if (options) {
      if (options.multipart) {
        headers["Content-Type"] = "multipart/form-data";
      }
      if (options.formData) {
        let formData = new FormData();
        for (let key in data) {
          formData.append(key, data[key]);
        }
        data = formData;
      }
    }
    return axios
      .post(`${API_URL}${url}`, data, { headers })
      .then((response) => this.checkErrorsWithStatusCode200(response.data))
      .catch((err) => {
        console.error(err.response);
        if (err && err.response && err.response.status === 401) {
          if (!err.response.data.logoff) emitToastr("error", "Erro de servidor! Confira se as informações enviadas estão completas e no formato correto e tente novamente.", "Erro");
          if (err.response.data && err.response.data.logoff === true) {
            if (err.response.data.message) emitToastr("warning", err.response.data.message, "Erro");
            setTimeout(() => {
              this.logout();
            }, 4000)
          }
        } else {
          if (err.response && err.response.data && err.response.data.error && err.response.data.message) {
            return emitToastr("error", err.response.data.message);
          } else if (err.response && err.response.status) emitToastr("error", err.response.statusText + " (status: " + err.response.status + ")", "Erro");
          else emitToastr("error", "Erro genérico de servidor!", "Erro");
          err.response.error = err.response.error || true;
          return err.response;
        }
      });
  };
}

export default new Api();
