import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Motos from "../views/Motos.vue";
import Profile from "../views/Profile.vue";
import StoreAdd from "../views/StoreAdd.vue";
import MotoAdd from "../views/MotoAdd.vue";
import UserAdd from "../views/UserAdd.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/motos",
    name: "Motos",
    component: Motos,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/meu-perfil",
    name: "Perfil",
    component: Profile,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/lojas",
    name: "Lojas",
    component: StoreAdd,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/cadastrar-moto",
    name: "MotoAdd",
    component: MotoAdd,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/usuarios",
    name: "UserAdd",
    component: UserAdd,
    meta: {
      layout: "menu",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/", "/password-recovery"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("token");

  if (authRequired && !loggedIn) {
    next("/");
  } else {
    next();
  }
});

export default router;
